import "./App.css";
import Button from "./components/Button";
import Features from "./components/Features";
import teamJson from "./resources/team.json";

import {useEffect, useRef} from "react";
import Team from "./components/Team";

import {faGlobe, faLocationDot, faPencil, faUsers} from '@fortawesome/free-solid-svg-icons'
import Download from "./components/Download";

const googlePlayUrl = "https://play.google.com/store/apps/details?id=com.Netmark.Netmark"
const features = [
  {
    icon: faPencil,
    title: "Create",
    description:
      "Post 3D spatial drawings, text, and even photos into the digital world",
    scale: 0.7,
  },
  {
    icon: faUsers,
    title: "Interact",
    description: "Remix, react, and respond to creations you find",
    scale: 0.7,
  },
  {
    icon: faLocationDot,
    title: "Discover",
    description:
      "See your friend’s creations all over the world in the interactive map",
    scale: 0.4,
  },
  {
    icon: faGlobe,
    title: "Share",
    description:
      "Export your creations to other apps like Instagram, Snapchat, or TikTok",
    scale: 0.6,
  },
];

function App() {
  const downloadTarget = useRef(null);

  const scrollToTarget = (target) => {
    if (target.current) {
      target.current.scrollIntoView({behavior: "smooth"});
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#download') {
      const downloadSection = document.getElementById('download');
      if (downloadSection) {
        downloadSection.scrollIntoView({behavior: 'smooth'});
      }
    }
  }, []);

  return (
    <div className="container">
      <div className="intro foreground">
        <div className="intro-logo">
          <img src="/logo/logo-background-title-white.svg" alt="logo"/>
        </div>
        <div className="intro-text secondary-font">
          <p>
            Hi, we are DROPZ, your path towards digital freedom
          </p>
          <p>
            DROPZ is a geolocation-based AR social network that empowers you to leave your
            mark anywhere, anytime. Our main aim is to establish a comfortable and welcoming
            environment for expressing uniqueness and individualism.
          </p>
          <div className="container">
            <Button text="Download Now" onClick={() => scrollToTarget(downloadTarget)}/>
          </div>
        </div>
      </div>

      <Features features={features}/>

      <Team team={teamJson.team}/>

      <div id="download" ref={downloadTarget}>
        <Download appUrl={googlePlayUrl}/>
      </div>
    </div>
  );
}

export default App;
