import React from "react";
import "./Team.css";

const Team = ({ team }) => {
  return (
    <div className="team-row">
      <div className="primary-header">Meet Our Team</div>
      <div className="team-list">
        {team.map((member, index) => {
          return (
            <div key={index} className={`member-container team-${index+1}`}>
              <div className="image-name-description">
                <div className="profile-container">
                  <img src={`../images/team/${member.image}`} alt="member" />
                </div>
                <div className="member-name">{member.name}</div>
                <div className="member-title"><a href={member.url} target="_blank" className="nostyle">{member.title}</a></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Team;
