import React from "react";
import "./Button.css";

const Button = ({ text, onClick, className }) => {
  return (
    <div onClick={onClick} className={"button " + className} >
      <div className="text">{text}</div>
    </div>
  );
};

export default Button;
